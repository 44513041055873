<template>
  <div class="nav-wrapper" :class="{'open': isSideNavOpen }">
    <div class="menu-icon" @click="this.isSideNavOpen = !this.isSideNavOpen">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  <nav id="nav">
    <div class="letter-icon" v-if="firstLetterOfEmail !== ''">
			{{ firstLetterOfEmail }}
		</div>
    <router-link to="/cookies">Cookies</router-link>
    <router-link to="/about">About</router-link>
    <a href="https://dnbr.to/docs/dunbar_privacy_policy.pdf">Privacy</a>
    <a href="#" class="close" @click="this.isSideNavOpen = !this.isSideNavOpen">Close 
      <img :src="closeIcon" alt="Close" />
    </a>
  </nav>
  </div>
  
  <router-view/>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import closeIcon from "./assets/close-icon.svg";


export default {
  name: 'App',
  data() {
    return {
      isSideNavOpen: false,
      firstLetterOfEmail: '',
      closeIcon
    };
  },
  async created() {
    let data = await new Promise((resolve) => {
      let timedOut = false;
      setTimeout(() => {
        timedOut = true;
        resolve(null);
      }, 2000);

      // Talk to extension to see if the user has a subscription
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage("ohhblkgfjjbjcaddddgooclkoeiiklgk", { type: "fetch" },
        (response) => {
          if (timedOut) return;
          console.log(response);
          resolve(response);
        });
    });
    if (data && data.displayName) {
      this.firstLetterOfEmail = Array.from(data.displayName ?? ' ')[0];
    }
  },
  mounted() {
    if (typeof window !== "undefined") {
      if (location.host === "ref.email" && !location.pathname.startsWith("/rfc822msgid")) {
        location.href = location.href.replace("https://ref.email", "https://reference.email");
      }
    }
  }
}
</script>


<style>
html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  display: flex;
  flex-direction: column;

}

.nav-wrapper {
  .menu-icon {
    position: fixed;
    top: 5vw;
    right: 5vw;
    width: 2rem;
    height: 2rem;
    z-index: 1;

    .line {
      position: absolute;
      display: block;
      background-color: #fff;
      width: 100%;
      height: 10%;

      /* transition: 1s all; */
    }

    .line:nth-of-type(1) {
      top: 0;
    }

    .line:nth-of-type(2) {
      top: 45%;
    }

    .line:nth-of-type(3) {
      bottom: 0;
    }

    
  }

  &.open {
    .menu-icon {

      .line:nth-of-type(1) {
        transform-origin: top left;
        transform: rotate(45deg);
        width: 2.82rem;
      }

      .line:nth-of-type(2) {
        opacity: 0;
      }

      .line:nth-of-type(3) {
        transform-origin: bottom left;
        transform: rotate(-45deg);
        width: 2.82rem;
      }
    }
  }
}

nav {
  transition: opacity .7s ease;

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;

    
    &.router-link-exact-active {
      color: dodgerblue;
    }
  }
}

@media(min-width: 768.01px ) {
  nav {
    text-align: right;
    position: fixed;
    left: 0px; right: 0px;
    top: 0px;
    padding: 30px;
    z-index: 2;

    a {

      margin: 0px 1rem;

    }
  }

  .nav-wrapper {
    .menu-icon {
      display: none;
    }

    a.close {
      display: none;
    }
  }
}


@media(max-width: 768px) {
  .nav-wrapper {
    nav {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      width: 15rem;
      text-align: right;
      transform: translateX(15rem);
      background-color: #6cc9a5;
      filter: none;
      padding-top: 2.5rem;
      transition: transform .7s ease-out, filter .7s ease-out, opacity .7s ease;

      a {
        margin-left: 5rem;
        padding: .25rem .75rem .25rem 1rem;
        text-decoration: none;
        display: block;
        transition: 0.3s;
        font-size: 1.5rem;

        &.close {
          border-left: 3px solid #0008;
          border-top: 3px solid #0008;
          border-bottom: 3px solid #0008;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;

          img {
            width: 1.75rem;
            vertical-align: bottom;
          }
        }
      }

      .letter-icon {
        display: inline-block;
        position: absolute;
        top: .75rem;
        right: .5rem;
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.5rem;
        margin-right: .5rem;
        background-color: #bd4343;
        border-radius: 0.75rem;
        color: #fff;
        font-weight: bold;
        text-align: center;
        line-height: 1.57rem;
      }

    }

    &.open {
      nav {
        transform: translateX(0);
        filter: drop-shadow(0 0 0.75rem #000);
      }
    }

  }
}

.page {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
</style>
